<template>
    <div class="d-flex flex-column justify-content-end">
      <div class="">
        <div class="quizell-ai-title mb-1" >
            Which products you would like to match? </div>
  
      </div>
     
    <div class="row align-items-center ">
        <div class="col-12 col-md-9">

            <div class="card" style="border-radius: 12px; border-color: rgba(24, 25, 28, 0.08);">
          <div class="card-body" style="padding: 16px;">
            <div class="d-flex justify-content-center align-items-center">
              <div class="">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.5 3C11.2239 3 12.8772 3.68482 14.0962 4.90381C15.3152 6.12279 16 7.77609 16 9.5C16 11.11 15.41 12.59 14.44 13.73L14.71 14H15.5L20.5 19L19 20.5L14 15.5V14.71L13.73 14.44C12.59 15.41 11.11 16 9.5 16C7.77609 16 6.12279 15.3152 4.90381 14.0962C3.68482 12.8772 3 11.2239 3 9.5C3 7.77609 3.68482 6.12279 4.90381 4.90381C6.12279 3.68482 7.77609 3 9.5 3ZM9.5 5C7 5 5 7 5 9.5C5 12 7 14 9.5 14C12 14 14 12 14 9.5C14 7 12 5 9.5 5Z" fill="#73738D"/>
    </svg>
    
    
    
              </div>
              <div class="" style="flex:1">
                <input type="text" name="" v-model="text" class="w-100 importQuizTextArea"
                  placeholder="Search products" id="">
              </div>
            </div>
          </div>
        </div>
        </div>
    <div class="col-6 col-md-2">
        <b-form-checkbox
                    
                    size="lg"
                   
                    class="checkbox "><span style="color:#73738D;font-size: 14px;"> Select all products</span></b-form-checkbox>
    </div>
    <div  class=" col-6 col-md-1 d-flex align-items-center" style="cursor:pointer; color:#73738D; font-size: 14px;">
        <span class="mr-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 3C10.6131 3 8.32387 3.94821 6.63604 5.63604C4.94821 7.32387 4 9.61305 4 12H1L4.89 15.89L4.96 16.03L9 12H6C6 10.1435 6.7375 8.36301 8.05025 7.05025C9.36301 5.7375 11.1435 5 13 5C14.8565 5 16.637 5.7375 17.9497 7.05025C19.2625 8.36301 20 10.1435 20 12C20 13.8565 19.2625 15.637 17.9497 16.9497C16.637 18.2625 14.8565 19 13 19C11.07 19 9.32 18.21 8.06 16.94L6.64 18.36C8.27 20 10.5 21 13 21C15.3869 21 17.6761 20.0518 19.364 18.364C21.0518 16.6761 22 14.3869 22 12C22 9.61305 21.0518 7.32387 19.364 5.63604C17.6761 3.94821 15.3869 3 13 3Z" fill="#73738D"/>
</svg>
</span>
Reset
    </div>
    </div>
      <!-- <div class="mt-2" style="box-shadow: 0px -2px 20px 0px rgba(24, 25, 28, 0.08);"></div> -->
      <div class="mt-3 row" style="max-height: 60vh; overflow:auto" v-if="isFirstTimeTableLoad">
      
          <div class="col-2 col-md-4 my-2" v-for="index in 6" :key="index">
            <div class="card" style="border-radius: 12px; border-color: rgba(24, 25, 28, 0.08);">
            <div class="card-body" style="padding: 16px;">
            
              <div 
         class="integrationImgae bg-preload"
         style="height: 100px; width: 100%; border-radius: 14px">
         <div class="into-preload"></div>
       </div>
            </div></div>
          </div>
        </div>
<div class="row mt-3" v-else  style="max-height: 60vh; overflow:auto">
    <div class="col-2 my-2 col-md-4" v-for="product in Products" :key="product.id">
        <div class="card" style="border-radius: 12px; border-color: rgba(24, 25, 28, 0.08);">
            <div class="card-body" style="padding: 16px;">
            <div class="d-flex justify-content-between " style="gap:10px">
                <div class="">
                    <lazy-image :src="product.image" style="width: 70px;
height: 70px; border-radius:8px;"></lazy-image>
                </div>
                <div class="">
                    <div class="ai-product-title">
                        {{ product.title }}
                    </div>
                    <div class="d-flex" style="gap:10px">
                        <div class="ai-price">$ {{ product.price }}</div>
                        <div class="ai-old-price" v-show="product.compare_at_price">$ {{ product.compare_at_price }}</div>
                    </div>
                </div>
                <div class="">
                    <b-form-checkbox
                    :value="product.id"
                v-model="selectedProducts"
                   
                    class="checkbox "></b-form-checkbox>
                </div>
            </div>
            </div>
        </div>
    </div>
   

</div>
      <div class="row box-with-shadow mt-3" >
              <div class="col-12 d-flex justify-content-end">
                  <Button @click="$emit('click')" class="mr-2" style="background: #B3AFB6; min-width: 20px;">Skip </Button>
                  <Button @click="$emit('click')">Next Step <span class="mr-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M3.33334 9.16669V10.8334H13.3333L8.75001 15.4167L9.93334 16.6L16.5333 10L9.93334 3.40002L8.75001 4.58336L13.3333 9.16669H3.33334Z" fill="white"/>
      </svg></span> </Button>
              </div>
          </div>
   
    </div>
  
  </template>
  <script>
  import axios from 'axios';
  import Button from '../../Layout/Button.vue';
  export default {
    components: {
      Button
    },
    watch: {
     
    },
    data() {
      return {
        text: '',
        loadingProducts:false,
        currentPage: 1,
      totalRows: 10,
      perPage: 10,
      Products:[],
      productFilter:{title: "",tag:'',collection_ids:[],status:''},
      isFirstTimeTableLoad:true,
      selectedProducts:[]
      }
    },
    methods:{
      async loadSavedProducts() {
      this.loadingProducts = true;
      try {
        const response = await axios.post('/loadSavedProducts',{page:this.currentPage,...this.productFilter});
        if (response.status == 200) {
          this.Products = response.data.data.data;
          this.perPage = response.data.data.per_page;
          this.totalRows = response.data.data.total;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {

        this.loadingProducts = false;
        this.isFirstTimeTableLoad = false;
      }
    },
    },
    mounted(){
      this.loadSavedProducts();
    }
  }
  </script>
  <style scoped>
  .ai-product-title{
    color: #1F1F28;

font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  .ai-price{
    color: #4D1B7E;

font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
  }
  .ai-old-price{
   
color: #73738D;

font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: line-through;
  }
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #4D1B7E;
  background-color: #4D1B7E !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #4D1B7E;
}
  
  .quizell-ai-title {
    color: #1F1F28;
  
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
  
    letter-spacing: 0.88
  }
  </style>
  